import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireGroupeClass } from "../../../../../core/services/formulaire/formulaire-groupe.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';


import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageDb0051 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    bdgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bdgMultiOccurrence: IMultiOccurrence;
    pruMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pruMultiOccurrence: IMultiOccurrence;
    refMultiOccurrenceOptions: IMultiOccurrenceOptions;
    refMultiOccurrence: IMultiOccurrence;
    prsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prsMultiOccurrence: IMultiOccurrence;
    bloMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bloMultiOccurrence: IMultiOccurrence;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Db0051Controller(Formulaire: IFormulaireClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    db2005Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite
) {
    const vm: IPageDb0051 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('peccodcleint_de'),
        new FormulaireItem('peccodcleint_a'),
        new FormulaireItem('naturecri'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prucleint', { lovSelectionMultiple: true }),
        new FormulaireItem('cptcleint', { lovSelectionMultiple: true }),
        new FormulaireItem('el1cleint', { lovSelectionMultiple: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prscleint'),
        new FormulaireItem('unacleint', { lovSelectionMultiple: true }),
        new FormulaireItem('cifcleint', { lovSelectionMultiple: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prudatfin_de'),
        new FormulaireItem('prudatfin_a'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('statutcri', { default: vm.monoOccurrence?.ecranDetails.valeurs.statutcri }),
        new FormulaireItem('typbdgcri', { default: vm.monoOccurrence?.ecranDetails.valeurs.typbdgcri }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('blocleint', { lovSelectionMultiple: true }),
        new FormulaireItem('intcleint', { required: false, lovSelectionMultiple: true }),
        new FormulaireItem('orgcleint', { lovSelectionMultiple: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('sfucleint', { lovSelectionMultiple: true }),
        new FormulaireItem('vecprucatprocri'),
        new FormulaireItem('grpcleint', { lovSelectionMultiple: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('soncleint', { lovSelectionMultiple: true }),
        new FormulaireItem('flgorg')
    ], { largeurDefaut: 30 })

    vm.multiOccurrenceOptions = {
        srccod: "db0051",//important afin d'obtenir les datatypes et les libelles
        isTab: true,
        autoFetch: false,
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres: criteresSuggeres,
        colonnesVisibles: [
            { nom: 'peccodcleint_de', largeur: 16 },
            { nom: 'peccodcleint_a', largeur: 17 },
            { nom: 'naturecri', largeur: 17 },
            { nom: 'prucleint', largeur: 17 },
            { nom: 'cptcleint', largeur: 17 },
            { nom: 'el1cleint', largeur: 17 },
            { nom: 'prscleint', largeur: 17 },
            { nom: 'unacleint', largeur: 17 },
            { nom: 'cifcleint', largeur: 17 },
            { nom: 'prudatfin_de', largeur: 17 },
            { nom: 'prudatfin_a', largeur: 17 },
            { nom: 'statutcri', largeur: 17 },
            { nom: 'typbdgcri', largeur: 17 },
            { nom: 'blocleint', largeur: 17 },
            { nom: 'intcleint', largeur: 17 },
            { nom: 'orgcleint', largeur: 17 },
            { nom: 'sfucleint', largeur: 17 },
            { nom: 'vecprucatprocri', largeur: 17 },
            { nom: 'grpcleint', largeur: 17 },
            { nom: 'soncleint', largeur: 17 },
            { nom: 'flgorg', largeur: 17 }
        ]
    }

    vm.bdgMultiOccurrenceOptions = {
        isTab: true,
        tabSelected: true,
        bloc: 'bdg',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcleint,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleint,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 1
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 260 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbi !== 1,
                titre: vm.valeursEcran.libmntbi
            },
            { nom: 'mntbu', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbu !== 1,
                titre: vm.valeursEcran.libmntbu
            },
            { nom: 'mntgb', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgb !== 1,
                titre: vm.valeursEcran.libmntgb
            },
            { nom: 'mntrg',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrg !== 1,
                titre: vm.valeursEcran.libmntrg
            },
            { nom: 'mntrb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrb !== 1,
                titre: vm.valeursEcran.libmntrb
            },
            { nom: 'mntrr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrr !== 1,
                titre: vm.valeursEcran.libmntrr
            },
            { nom: 'mntpe',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpe !== 1,
                titre: vm.valeursEcran.libmntpe
            },
            { nom: 'mnten',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmnten !== 1,
                titre: vm.valeursEcran.libmnten
            },
            { nom: 'mntre',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntre !== 1,
                titre: vm.valeursEcran.libmntre
            },
            { nom: 'mntgs',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgs !== 1,
                titre: vm.valeursEcran.libmntgs
            },
            { nom: 'mntib',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntib !== 1,
                titre: vm.valeursEcran.libmntib
            },
            { nom: 'mntbb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbb !== 1,
                titre: vm.valeursEcran.libmntbb
            },
            { nom: 'mntbr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbr !== 1,
                titre: vm.valeursEcran.libmntbr
            },
            { nom: 'mntpb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpb !== 1,
                titre: vm.valeursEcran.libmntpb
            },
            { nom: 'mntce',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntce !== 1,
                titre: vm.valeursEcran.libmntce
            },
            { nom: 'mntcr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntcr !== 1,
                titre: vm.valeursEcran.libmntcr
            },
            { nom: 'mntrf',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrf !== 1,
                titre: vm.valeursEcran.libmntrf
            },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'prudatdeb', largeur: 130 },
            { nom: 'prudatfin', largeur: 130 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc_categ', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 }
        ],
        colonnesCachees: ['pmtcleint',
            'intcodnumcri',
            'intcleint',
            'sfucleint',
            'prucleint',
            'prscleint',
            'cifcleint',
            'cptcleint',
            'unacleint',
            'el1cleint',
            'el2cleint',
            'el3cleint',
            'grpcleint',
            'soncleint',
            'orgcleint',
            'blocleint',
            'prudatfin',
            'prudatdeb',
            'unacleint']
    };

    vm.pruMultiOccurrenceOptions = {
        isTab: true,
        bloc: 'pru',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcleint,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: rowData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleint,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 2
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 260 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbi !== 1,
                titre: vm.valeursEcran.libmntbi
            },
            { nom: 'mntbu', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbu !== 1,
                titre: vm.valeursEcran.libmntbu
            },
            { nom: 'mntgb', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgb !== 1,
                titre: vm.valeursEcran.libmntgb
            },
            { nom: 'mntrg',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrg !== 1,
                titre: vm.valeursEcran.libmntrg
            },
            { nom: 'mntrb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrb !== 1,
                titre: vm.valeursEcran.libmntrb
            },
            { nom: 'mntrr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrr !== 1,
                titre: vm.valeursEcran.libmntrr
            },
            { nom: 'mntpe',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpe !== 1,
                titre: vm.valeursEcran.libmntpe
            },
            { nom: 'mnten',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmnten !== 1,
                titre: vm.valeursEcran.libmnten
            },
            { nom: 'mntre',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntre !== 1,
                titre: vm.valeursEcran.libmntre
            },
            { nom: 'mntgs',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgs !== 1,
                titre: vm.valeursEcran.libmntgs
            },
            { nom: 'mntib',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntib !== 1,
                titre: vm.valeursEcran.libmntib
            },
            { nom: 'mntbb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbb !== 1,
                titre: vm.valeursEcran.libmntbb
            },
            { nom: 'mntbr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbr !== 1,
                titre: vm.valeursEcran.libmntbr
            },
            { nom: 'mntpb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpb !== 1,
                titre: vm.valeursEcran.libmntpb
            },
            { nom: 'mntce',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntce !== 1,
                titre: vm.valeursEcran.libmntce
            },
            { nom: 'mntcr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntcr !== 1,
                titre: vm.valeursEcran.libmntcr
            },
            { nom: 'mntrf',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrf !== 1,
                titre: vm.valeursEcran.libmntrf
            },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc_categ', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prscod', largeur: 100 },
            { nom: 'prsdscl01', largeur: 130 }
        ],
        colonnesCachees: ['prscleint']
    };

    vm.refMultiOccurrenceOptions = {
        isTab: true,
        bloc: 'ref',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcleint,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: rowData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleint,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 3
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 260 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbi !== 1,
                titre: vm.valeursEcran.libmntbi
            },
            { nom: 'mntbu', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbu !== 1,
                titre: vm.valeursEcran.libmntbu
            },
            { nom: 'mntgb', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgb !== 1,
                titre: vm.valeursEcran.libmntgb
            },
            { nom: 'mntrg',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrg !== 1,
                titre: vm.valeursEcran.libmntrg
            },
            { nom: 'mntrb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrb !== 1,
                titre: vm.valeursEcran.libmntrb
            },
            { nom: 'mntrr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrr !== 1,
                titre: vm.valeursEcran.libmntrr
            },
            { nom: 'mntpe',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpe !== 1,
                titre: vm.valeursEcran.libmntpe
            },
            { nom: 'mnten',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmnten !== 1,
                titre: vm.valeursEcran.libmnten
            },
            { nom: 'mntre',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntre !== 1,
                titre: vm.valeursEcran.libmntre
            },
            { nom: 'mntgs',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgs !== 1,
                titre: vm.valeursEcran.libmntgs
            },
            { nom: 'mntib',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntib !== 1,
                titre: vm.valeursEcran.libmntib
            },
            { nom: 'mntbb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbb !== 1,
                titre: vm.valeursEcran.libmntbb
            },
            { nom: 'mntbr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbr !== 1,
                titre: vm.valeursEcran.libmntbr
            },
            { nom: 'mntpb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpb !== 1,
                titre: vm.valeursEcran.libmntpb
            },
            { nom: 'mntce',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntce !== 1,
                titre: vm.valeursEcran.libmntce
            },
            { nom: 'mntcr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntcr !== 1,
                titre: vm.valeursEcran.libmntcr
            },
            { nom: 'mntrf',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrf !== 1,
                titre: vm.valeursEcran.libmntrf
            },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc_categ', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prscod', largeur: 130 },
            { nom: 'prsdscl01', largeur: 130 },
            { nom: 'blocod', largeur: 130 },
            { nom: 'blodscabr', largeur: 130 }
        ],
        colonnesCachees: ['prscleint', 'blocleint']
    };

    vm.prsMultiOccurrenceOptions = {
        isTab: true,
        bloc: 'prs',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcleint,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: rowData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: rowData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleint,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 4
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 260 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbi !== 1,
                titre: vm.valeursEcran.libmntbi
            },
            { nom: 'mntbu', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbu !== 1,
                titre: vm.valeursEcran.libmntbu
            },
            { nom: 'mntgb', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgb !== 1,
                titre: vm.valeursEcran.libmntgb
            },
            { nom: 'mntrg',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrg !== 1,
                titre: vm.valeursEcran.libmntrg
            },
            { nom: 'mntrb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrb !== 1,
                titre: vm.valeursEcran.libmntrb
            },
            { nom: 'mntrr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrr !== 1,
                titre: vm.valeursEcran.libmntrr
            },
            { nom: 'mntpe',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpe !== 1,
                titre: vm.valeursEcran.libmntpe
            },
            { nom: 'mnten',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmnten !== 1,
                titre: vm.valeursEcran.libmnten
            },
            { nom: 'mntre',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntre !== 1,
                titre: vm.valeursEcran.libmntre
            },
            { nom: 'mntgs',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgs !== 1,
                titre: vm.valeursEcran.libmntgs
            },
            { nom: 'mntib',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntib !== 1,
                titre: vm.valeursEcran.libmntib
            },
            { nom: 'mntbb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbb !== 1,
                titre: vm.valeursEcran.libmntbb
            },
            { nom: 'mntbr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbr !== 1,
                titre: vm.valeursEcran.libmntbr
            },
            { nom: 'mntpb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpb !== 1,
                titre: vm.valeursEcran.libmntpb
            },
            { nom: 'mntce',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntce !== 1,
                titre: vm.valeursEcran.libmntce
            },
            { nom: 'mntcr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntcr !== 1,
                titre: vm.valeursEcran.libmntcr
            },
            { nom: 'mntrf',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrf !== 1,
                titre: vm.valeursEcran.libmntrf
            },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc_categ', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prscod', largeur: 130 },
            { nom: 'prsdscl01', largeur: 130 },
            { nom: 'blocod', largeur: 130 },
            { nom: 'blodscabr', largeur: 130 },
            { nom: 'cptcod', largeur: 130 },
            { nom: 'cptdscabr', largeur: 130 }
        ],
        colonnesCachees: ['prscleint', 'blocleint']
    };
    vm.bloMultiOccurrenceOptions = {
        isTab: true,
        bloc: 'blo',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 260 }
        ],
        colonnesVisibles: [
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'categ_ubr', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prscod', largeur: 130 },
            { nom: 'prsdscl01', largeur: 130 },
            { nom: 'blocod', largeur: 130 },
            { nom: 'blodscabr', largeur: 130 },
            { nom: 'cptcod', largeur: 130 },
            { nom: 'cptdscabr', largeur: 130 },
            { nom: 'el1cod', largeur: 130 },
            { nom: 'el1dscabr', largeur: 130 },
            { nom: 'unacod', largeur: 130 },
            { nom: 'unadscabr', largeur: 130 },
            { nom: 'cifcod', largeur: 130 },
            { nom: 'cifdsc', largeur: 130 },
            { nom: 'imp', largeur: 292 },
            { nom: 'type_piece', largeur: 130 },
            { nom: 'docaffnumdoc01', largeur: 130 },
            { nom: 'refcod', largeur: 130 },
            { nom: 'refnom', largeur: 130 },
            { nom: 'docaffdsc01', largeur: 250 },
            { nom: 'pecann', largeur: 130 },
            { nom: 'pecper', largeur: 130 },
            { nom: 'numref', largeur: 180 },
            { nom: 'commentaire', largeur: 250 },
            { nom: 'categ', largeur: 130 },
            { nom: 'categ_desc', largeur: 130 },
            { nom: 'mntbi', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbi !== 1,
                titre: vm.valeursEcran.libmntbi
            },
            { nom: 'mntbu', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbu !== 1,
                titre: vm.valeursEcran.libmntbu
            },
            { nom: 'mntgb', 
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgb !== 1,
                titre: vm.valeursEcran.libmntgb
            },
            { nom: 'mntrg',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrg !== 1,
                titre: vm.valeursEcran.libmntrg
            },
            { nom: 'mntrb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrb !== 1,
                titre: vm.valeursEcran.libmntrb
            },
            { nom: 'mntrr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrr !== 1,
                titre: vm.valeursEcran.libmntrr
            },
            { nom: 'mntpe',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpe !== 1,
                titre: vm.valeursEcran.libmntpe
            },
            { nom: 'mnten',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmnten !== 1,
                titre: vm.valeursEcran.libmnten
            },
            { nom: 'mntre',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntre !== 1,
                titre: vm.valeursEcran.libmntre
            },
            { nom: 'mntgs',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntgs !== 1,
                titre: vm.valeursEcran.libmntgs
            },
            { nom: 'mntib',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntib !== 1,
                titre: vm.valeursEcran.libmntib
            },
            { nom: 'mntbb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbb !== 1,
                titre: vm.valeursEcran.libmntbb
            },
            { nom: 'mntbr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntbr !== 1,
                titre: vm.valeursEcran.libmntbr
            },
            { nom: 'mntpb',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntpb !== 1,
                titre: vm.valeursEcran.libmntpb
            },
            { nom: 'mntce',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntce !== 1,
                titre: vm.valeursEcran.libmntce
            },
            { nom: 'mntcr',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntcr !== 1,
                titre: vm.valeursEcran.libmntcr
            },
            { nom: 'mntrf',
                largeur: 150,
                hidden: () => vm.valeursEcran.flgmntrf !== 1,
                titre: vm.valeursEcran.libmntrf
            }
        ]
    };
}
