import { IComponentController } from 'angular';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageDB0050 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence
    aMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gMultiOccurrenceOptions: IMultiOccurrenceOptions;
    titrea: any;
    titreb: any;
    titrec: any;
    titred: any;
    titree: any;
    titref: any;
    titreg: any;
    valeursEcran: any;
    aMultiOccurrence: IMultiOccurrence;
    bMultiOccurrence: IMultiOccurrence;
    cMultiOccurrence: IMultiOccurrence;
    dMultiOccurrence: IMultiOccurrence;
    eMultiOccurrence: IMultiOccurrence;
    fMultiOccurrence: IMultiOccurrence;
    gMultiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Db0050Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    db2002Route: IRoute,
    Pagination: IPaginationClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass) {

    const vm: IPageDB0050 = this;
    vm.$onInit = $onInit;
    function $onInit() {

        vm.titrea = ecranDetails.valeurs.titrea;
        vm.titreb = ecranDetails.valeurs.titreb;
        vm.titrec = ecranDetails.valeurs.titrec;
        vm.titred = ecranDetails.valeurs.titred;
        vm.titree = ecranDetails.valeurs.titree;
        vm.titref = ecranDetails.valeurs.titref;

        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            noId: true
        }

        const criteresSuggeres = new Formulaire([
            new FormulaireItem('peccoddeb', { required: true }),
            new FormulaireItem('peccodfin', { required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('prucleint', { titre: ecranDetails.valeurs.titpru, lovSelectionMultiple: true }),
            new FormulaireItem('cptcleint', { titre: ecranDetails.valeurs.titcpt, lovSelectionMultiple: true }),
            new FormulaireItem('prscleint', { titre: ecranDetails.valeurs.titprs }),
            new FormulaireItem('unacleint', { lovSelectionMultiple: true }),
            new FormulaireItem('cifcleint', { lovSelectionMultiple: true }),
            new FormulaireItem('el1cleint', { titre: ecranDetails.valeurs.titel1, hidden: !ecranDetails.valeurs.el1flg, lovSelectionMultiple: true }),
            new FormulaireItem('statut', { default: 1, required: true }),
            new FormulaireItem('type_bdg', { default: 'P', required: true }),
            new FormulaireItem('blocleint', { lovSelectionMultiple: true }),

            new FormulaireItem('intcleint', { titre: ecranDetails.valeurs.titint, lovSelectionMultiple: true }),
            new FormulaireItem('sfucleint', { lovSelectionMultiple: true }),
            new FormulaireItem('vecprucatpro', { titre: ecranDetails.valeurs.titveccat }),

            new FormulaireItem('grpcleint', { lovSelectionMultiple: true }),
            new FormulaireItem('soncleint', { lovSelectionMultiple: true }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('el2cleint', { titre: ecranDetails.valeurs.titel2, hidden: !ecranDetails.valeurs.el2flg }),
            new FormulaireItem('el3cleint', { titre: ecranDetails.valeurs.titel3, hidden: !ecranDetails.valeurs.el3flg })
        ], { largeurDefaut: 30 })

        vm.multiOccurrenceOptions = {
            srccod: "db0050",//important afin d'obtenir les datatypes et les libelles
            isTab: true,
            autoFetch: false,
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                recherche: false,
                filtrer: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                avisEmploye: false,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true,
                effaceCriteres: true
            },
            criteresSuggeres: criteresSuggeres,
            colonnesVisibles: [
                { nom: 'peccoddeb', largeur: 16 },
                { nom: 'peccodfin', largeur: 17 },
                { nom: 'prucleint', largeur: 17 },
                { nom: 'cptcleint', largeur: 17 },
                { nom: 'prscleint', largeur: 17 },
                { nom: 'unacleint', largeur: 17 },
                { nom: 'cifcleint', largeur: 17 },
                { nom: 'el1cleint', largeur: 17 },
                { nom: 'statut', largeur: 17 },
                { nom: 'type_bdg', largeur: 17 },
                { nom: 'blocleint', largeur: 17 },
                { nom: 'intcleint', largeur: 17 },
                { nom: 'sfucleint', largeur: 17 },
                { nom: 'vecprucatpro', largeur: 17 },
                { nom: 'grpcleint', largeur: 17 },
                { nom: 'soncleint', largeur: 17 },
                { nom: 'el2cleint', largeur: 17 },
                { nom: 'el3cleint', largeur: 17 },
            ]
        }

        vm.aMultiOccurrenceOptions = {
            isTab: true,
            tabSelected: true,
            navigatePage: db2002Route.NAME,
            navigateParams: (rowData: any) => ({
                peccoddeb: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
                cptcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
                el1cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                prscleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
                cifcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 1,
                blocleint: rowData.blocleint,
                intcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup
            }),
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: false,
                effaceCriteres: true,
                pagination: true,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true
            },
            criteresSuggeres,
            pagination: new Pagination({
                nombreElementPage: 100,
                nbElementsPossibles: [100, 250, 500]
            }), 
            colonnesFixesGauche: [
                { nom: 'vaedsc', largeur: 110 },
                { nom: 'blocod', largeur: 120 },
                { nom: 'blodscabr', largeur: 150 }
            ],
            colonnesVisibles: [
                { nom: 'mntrap', largeur: 160 },
                { nom: 'mntbi', largeur: 130 },
                { nom: 'mntbu', largeur: 110 },
                { nom: 'mntgb', largeur: 140 },
                { nom: 'mntrg', largeur: 140 },
                { nom: 'mntrb', largeur: 140 },
                { nom: 'mntrr', largeur: 110 },
                { nom: 'mntpe', largeur: 140 },
                { nom: 'mnten', largeur: 130 },
                { nom: 'mntre', largeur: 110 },
                { nom: 'mntgs', largeur: 110 },
                { nom: 'mntbr', largeur: 140 },
                { nom: 'mntpb', largeur: 170 },
                { nom: 'mnttot', largeur: 110 },
                { nom: 'mntect', largeur: 110 },
                { nom: 'mntprc', largeur: 140 }
            ],
            colonnesCachees: [
                'vaesysnatctb',
                'blocleint'
            ]
        }
        vm.bMultiOccurrenceOptions = {
            isTab: true,
            navigatePage: db2002Route.NAME,
            navigateParams: (rowData: any) => ({
                peccoddeb: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
                cptcleint: rowData.cptcleint,
                el1cleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                prscleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
                cifcleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 2,
                blocleint: rowData.blocleint,
                intcleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.bMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            }),
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: false,
                effaceCriteres: true,
                pagination: true,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true
            },
            criteresSuggeres,
            entetesReplacedValues: {
                cptcod: ecranDetails.valeurs.titcptabr,
                cptdscabr: ecranDetails.valeurs.dsccptabr
            },
            pagination: new Pagination({
                nombreElementPage: 100,
                nbElementsPossibles: [100, 250, 500]
            }),             
            colonnesFixesGauche: [
                { nom: 'vaedsc', largeur: 110 },
                { nom: 'blocod', largeur: 120 },
                { nom: 'blodscabr', largeur: 150 },
                { nom: 'cptcod', largeur: 120 },
                { nom: 'cptdscabr', largeur: 180 }
            ],
            colonnesVisibles: [
                { nom: 'mntrap', largeur: 160 },
                { nom: 'mntbi', largeur: 130 },
                { nom: 'mntbu', largeur: 110 },
                { nom: 'mntgb', largeur: 140 },
                { nom: 'mntrg', largeur: 140 },
                { nom: 'mntrb', largeur: 140 },
                { nom: 'mntrr', largeur: 110 },
                { nom: 'mntpe', largeur: 140 },
                { nom: 'mnten', largeur: 130 },
                { nom: 'mntre', largeur: 110 },
                { nom: 'mntgs', largeur: 110 },
                { nom: 'mntbr', largeur: 140 },
                { nom: 'mntpb', largeur: 170 },
                { nom: 'mnttot', largeur: 110 },
                { nom: 'mntect', largeur: 110 },
                { nom: 'mntprc', largeur: 140 }
            ],
            colonnesCachees: [
                'vaesysnatctb',
                'blocleint',
                'cptcleint'
            ]
        }

        vm.cMultiOccurrenceOptions = {
            isTab: true,
            navigatePage: db2002Route.NAME,
            navigateParams: (rowData: any) => ({
                peccoddeb: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: rowData.prucleint,
                el1cleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                prscleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
                cifcleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 3,
                blocleint: rowData.blocleint,
                cptcleint: rowData.cptcleint,
                intcleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.cMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            }),
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: false,
                effaceCriteres: true,
                pagination: true,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true
            },
            criteresSuggeres,
            entetesReplacedValues: {
                cptcod: ecranDetails.valeurs.titcptabr,
                cptdscabr: ecranDetails.valeurs.dsccptabr,
                prucod: ecranDetails.valeurs.titprucod,
                prudscl01: ecranDetails.valeurs.titprudsc,
                vecprucatpro: ecranDetails.valeurs.titveccat
            },
            pagination: new Pagination({
                nombreElementPage: 100,
                nbElementsPossibles: [100, 250, 500]
            }),             
            colonnesVisibles: [
                { nom: 'vaedsc', largeur: 110 },
                { nom: 'blocod', largeur: 120 },
                { nom: 'blodscabr', largeur: 150 },
                { nom: 'cptcod', largeur: 120 },
                { nom: 'cptdscabr', largeur: 180 },
                { nom: 'prucod', largeur: 120 },
                { nom: 'intcod', largeur: 120 },
                { nom: 'intnom', largeur: 160 },
                { nom: 'prudscl01', largeur: 160 },
                { nom: 'unacodsup', largeur: 130 },
                { nom: 'unadscsup', largeur: 180 },
                { nom: 'vecprucatpro', largeur: 140 },
                { nom: 't_vecdsc', largeur: 180 },
                { nom: 'pruflgexcpb', largeur: 110 },
                { nom: 'mntrap', largeur: 160 },
                { nom: 'mntbi', largeur: 130 },
                { nom: 'mntbu', largeur: 110 },
                { nom: 'mntgb', largeur: 140 },
                { nom: 'mntrg', largeur: 140 },
                { nom: 'mntrb', largeur: 140 },
                { nom: 'mntrr', largeur: 110 },
                { nom: 'mntpe', largeur: 140 },
                { nom: 'mnten', largeur: 130 },
                { nom: 'mntre', largeur: 110 },
                { nom: 'mntgs', largeur: 110 },
                { nom: 'mntbr', largeur: 140 },
                { nom: 'mntpb', largeur: 170 },
                { nom: 'mnttot', largeur: 110 },
                { nom: 'mntect', largeur: 110 },
                { nom: 'mntprc', largeur: 140 }
            ],
            colonnesCachees: [
                'vaesysnatctb',
                'blocleint',
                'prucleint',
                'cptcleint'
            ]
        }

        vm.dMultiOccurrenceOptions = {
            isTab: true,
            navigatePage: db2002Route.NAME,
            navigateParams: (rowData: any) => ({
                peccoddeb: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: rowData.prucleint,
                el1cleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                cifcleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 4,
                blocleint: rowData.blocleint,
                prscleint: rowData.prscleint,
                cptcleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
                intcleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.dMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup
            }),
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: false,
                effaceCriteres: true,
                pagination: true,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true
            },
            criteresSuggeres,
            entetesReplacedValues: {
                cptcod: ecranDetails.valeurs.titcptabr,
                cptdscabr: ecranDetails.valeurs.dsccptabr,
                prucod: ecranDetails.valeurs.titprucod,
                prudscl01: ecranDetails.valeurs.titprudsc,
                vecprucatpro: ecranDetails.valeurs.titveccat,
                prscod: ecranDetails.valeurs.titprscod,
                prsdscl01: ecranDetails.valeurs.titprsdsc,
            },
            pagination: new Pagination({
                nombreElementPage: 100,
                nbElementsPossibles: [100, 250, 500]
            }),             
            colonnesVisibles: [
                { nom: 'vaedsc', largeur: 110 },
                { nom: 'prucod', largeur: 120 },
                { nom: 'intcod', largeur: 120 },
                { nom: 'intnom', largeur: 160 },
                { nom: 'prudscl01', largeur: 160 },
                { nom: 'unacodsup', largeur: 140 },
                { nom: 'unadscsup', largeur: 180 },
                { nom: 'prscod', largeur: 160 },
                { nom: 'prsdscl01', largeur: 160 },
                { nom: 'vecprucatpro', largeur: 140 },
                { nom: 't_vecdsc', largeur: 160 },
                { nom: 'pruflgexcpb', largeur: 110 },
                { nom: 'blocod', largeur: 120 },
                { nom: 'blodscabr', largeur: 160 },
                { nom: 'mntrap', largeur: 160 },
                { nom: 'mntbi', largeur: 130 },
                { nom: 'mntbu', largeur: 110 },
                { nom: 'mntgb', largeur: 140 },
                { nom: 'mntrg', largeur: 140 },
                { nom: 'mntrb', largeur: 140 },
                { nom: 'mntrr', largeur: 110 },
                { nom: 'mntpe', largeur: 140 },
                { nom: 'mnten', largeur: 130 },
                { nom: 'mntre', largeur: 110 },
                { nom: 'mntgs', largeur: 110 },
                { nom: 'mntbr', largeur: 140 },
                { nom: 'mntpb', largeur: 170 },
                { nom: 'mnttot', largeur: 110 },
                { nom: 'mntect', largeur: 110 },
                { nom: 'mntprc', largeur: 140 }
            ],
            colonnesCachees: [
                'vaesysnatctb',
                'blocleint',
                'prucleint',
                'prscleint'
            ]
        }
        vm.eMultiOccurrenceOptions = {
            isTab: true,
            navigatePage: db2002Route.NAME,
            navigateParams: (rowData: any) => ({
                peccoddeb: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: rowData.prucleint,
                el1cleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                cifcleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 5,
                blocleint: rowData.blocleint,
                prscleint: rowData.prscleint,
                cptcleint: rowData.cptcleint,
                intcleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.eMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup
            }),
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: false,
                effaceCriteres: true,
                pagination: true,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true
            },
            criteresSuggeres,
            entetesReplacedValues: {
                cptcod: ecranDetails.valeurs.titcptabr,
                cptdscabr: ecranDetails.valeurs.dsccptabr,
                prucod: ecranDetails.valeurs.titprucod,
                prudscl01: ecranDetails.valeurs.titprudsc,
                vecprucatpro: ecranDetails.valeurs.titveccat,
                prscod: ecranDetails.valeurs.titprscod,
                prsdscl01: ecranDetails.valeurs.titprsdsc,
            },
            pagination: new Pagination({
                nombreElementPage: 100,
                nbElementsPossibles: [100, 250, 500]
            }),             
            colonnesVisibles: [
                { nom: 'vaedsc', largeur: 110 },
                { nom: 'prucod', largeur: 110 },
                { nom: 'intcod', largeur: 140 },
                { nom: 'intnom', largeur: 180 },
                { nom: 'prudscl01', largeur: 160 },
                { nom: 'unacodsup', largeur: 130 },
                { nom: 'unadscsup', largeur: 160 },
                { nom: 'vecprucatpro', largeur: 130 },
                { nom: 't_vecdsc', largeur: 150 },
                { nom: 'pruflgexcpb', largeur: 110 },
                { nom: 'prscod', largeur: 120 },
                { nom: 'prsdscl01', largeur: 160 },
                { nom: 'blocod', largeur: 120 },
                { nom: 'blodscabr', largeur: 150 },
                { nom: 'cptcod', largeur: 130 },
                { nom: 'cptdscabr', largeur: 150 },
                { nom: 'mntrap', largeur: 160 },
                { nom: 'mntbi', largeur: 130 },
                { nom: 'mntbu', largeur: 110 },
                { nom: 'mntgb', largeur: 140 },
                { nom: 'mntrg', largeur: 140 },
                { nom: 'mntrb', largeur: 140 },
                { nom: 'mntrr', largeur: 110 },
                { nom: 'mntpe', largeur: 140 },
                { nom: 'mnten', largeur: 130 },
                { nom: 'mntre', largeur: 110 },
                { nom: 'mntgs', largeur: 110 },
                { nom: 'mntbr', largeur: 140 },
                { nom: 'mntpb', largeur: 170 },
                { nom: 'mnttot', largeur: 110 },
                { nom: 'mntect', largeur: 110 },
                { nom: 'mntprc', largeur: 140 }
            ],
            colonnesCachees: [
                'vaesysnatctb',
                'blocleint',
                'prucleint',
                'prscleint',
                'cptcleint'
            ]
        }

        vm.fMultiOccurrenceOptions = {
            isTab: true,
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: false,
                effaceCriteres: true,
                pagination: true,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true
            },
            criteresSuggeres,
            entetesReplacedValues: {
                cptcod: ecranDetails.valeurs.titcptabr,
                cptdscabr: ecranDetails.valeurs.dsccptabr,
                prucod: ecranDetails.valeurs.titprucod,
                prudscl01: ecranDetails.valeurs.titprudsc,
                vecprucatpro: ecranDetails.valeurs.titveccat,
                prscod: ecranDetails.valeurs.titprscod,
                prsdscl01: ecranDetails.valeurs.titprsdsc,
            },
            pagination: new Pagination({
                nombreElementPage: 100,
                nbElementsPossibles: [100, 250, 500]
            }),             
            colonnesVisibles: [
                { nom: 'vaedsc', largeur: 110 },
                { nom: 'prucod', largeur: 110 },
                { nom: 'intcod', largeur: 130 },
                { nom: 'intnom', largeur: 160 },
                { nom: 'prudscl01', largeur: 160 },
                { nom: 'unacodsup', largeur: 130 },
                { nom: 'unadscsup', largeur: 160 },
                { nom: 'prscod', largeur: 120 },
                { nom: 'prsdscl01', largeur: 160 },
                { nom: 'vecprucatpro', largeur: 130 },
                { nom: 't_vecdsc', largeur: 150 },
                { nom: 'pruflgexcpb', largeur: 110 },
                { nom: 'blocod', largeur: 120 },
                { nom: 'blodscabr', largeur: 150 },
                { nom: 'cptcod', largeur: 130 },
                { nom: 'cptdscabr', largeur: 150 },
                { nom: 'el1cod', largeur: 150 },
                { nom: 'el1dscabr', largeur: 190 },
                { nom: 'unacod', largeur: 140 },
                { nom: 'unadscabr', largeur: 170 },
                { nom: 'cifcod', largeur: 140 },
                { nom: 'cifdsc', largeur: 170 },
                { nom: 't_imp', largeur: 230 },
                { nom: 'type_piece', largeur: 130 },
                { nom: 'docaffnumdoc01', largeur: 160 },
                { nom: 'refcod', largeur: 120 },
                { nom: 'refnom', largeur: 160 },
                { nom: 'docaffdsc01', largeur: 160 },
                { nom: 'pecann', largeur: 110 },
                { nom: 'pecper', largeur: 110 },
                { nom: 'numref', largeur: 140 },
                { nom: 't_commentaire', largeur: 240 },
                { nom: 'categ', largeur: 140 },
                { nom: 'categ_desc', largeur: 160 },
                { nom: 'mntrap', largeur: 160 },
                { nom: 'mntbi', largeur: 130 },
                { nom: 'mntbu', largeur: 110 },
                { nom: 'mntgb', largeur: 140 },
                { nom: 'mntrg', largeur: 140 },
                { nom: 'mntrb', largeur: 141 },
                { nom: 'mntrr', largeur: 110 },
                { nom: 'mntpe', largeur: 140 },
                { nom: 'mnten', largeur: 130 },
                { nom: 'mntre', largeur: 110 },
                { nom: 'mntgs', largeur: 110 },
                { nom: 'mntbr', largeur: 140 },
                { nom: 'mntpb', largeur: 170 }
            ]
        }

        vm.gMultiOccurrenceOptions = {
            isTab: true,
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: false,
                effaceCriteres: true,
                pagination: true,
                criteresSuggeresVisibles: true,
                reinitialiserOuvrirCriteres: true
            },
            criteresSuggeres,
            entetesReplacedValues: {
                cptcod: ecranDetails.valeurs.titcptabr,
                cptdscabr: ecranDetails.valeurs.dsccptabr,
                prucod: ecranDetails.valeurs.titprucod,
                prudscl01: ecranDetails.valeurs.titprudsc,
                vecprucatpro: ecranDetails.valeurs.titveccat,
                prscod: ecranDetails.valeurs.titprscod,
                prsdscl01: ecranDetails.valeurs.titprsdsc,
            },
            pagination: new Pagination({
                nombreElementPage: 100,
                nbElementsPossibles: [100, 250, 500]
            }),             
            colonnesVisibles: [
                { nom: 'vaedsc', largeur: 110 },
                { nom: 'prucod', largeur: 110 },
                { nom: 'intcod', largeur: 130 },
                { nom: 'intnom', largeur: 160 },
                { nom: 'prudscl01', largeur: 160 },
                { nom: 'unacodsup', largeur: 130 },
                { nom: 'unadscsup', largeur: 160 },
                { nom: 'prscod', largeur: 120 },
                { nom: 'prsdscl01', largeur: 160 },
                { nom: 'vecprucatpro', largeur: 130 },
                { nom: 't_vecdsc', largeur: 150 },
                { nom: 'pruflgexcpb', largeur: 110 },
                { nom: 'blocod', largeur: 120 },
                { nom: 'blodscabr', largeur: 150 },
                { nom: 'cptcod', largeur: 130 },
                { nom: 'cptdscabr', largeur: 150 },
                { nom: 'el1cod', largeur: 150 },
                { nom: 'el1dscabr', largeur: 190 },
                { nom: 'unacod', largeur: 140 },
                { nom: 'unadscabr', largeur: 170 },
                { nom: 'cifcod', largeur: 140 },
                { nom: 'cifdsc', largeur: 170 },
                { nom: 't_imp', largeur: 230 },
                { nom: 'type_piece', largeur: 130 },
                { nom: 'docaffnumdoc01', largeur: 160 },
                { nom: 'refcod', largeur: 120 },
                { nom: 'refnom', largeur: 160 },
                { nom: 'docaffdsc01', largeur: 160 },
                { nom: 'pecann', largeur: 110 },
                { nom: 'pecper', largeur: 110 },
                { nom: 'drenumref', largeur: 140 },
                { nom: 't_commentaire', largeur: 240 },
                { nom: 'categ', largeur: 140 },
                { nom: 'categ_desc', largeur: 160 },
                { nom: 'mntrap', largeur: 160 },
                { nom: 'mntbi', largeur: 130 },
                { nom: 'mntbu', largeur: 110 },
                { nom: 'mntgb', largeur: 140 },
                { nom: 'mntrg', largeur: 140 },
                { nom: 'mntrb', largeur: 141 },
                { nom: 'mntrr', largeur: 110 },
                { nom: 'mntpe', largeur: 140 },
                { nom: 'mnten', largeur: 130 },
                { nom: 'mntre', largeur: 110 },
                { nom: 'mntgs', largeur: 110 },
                { nom: 'mntpb', largeur: 170 }
            ]
        }
    }
}
